import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { useNavigate } from "react-router-dom";
import { ValidaForm2 } from "../components/Validacao";
import { db, auth, storage } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const Form2 = () => {
  const [erro, setErro] = useState({});
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const uploadFile = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `desconto2025/${uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setDados({ ...dados, [docUrl]: url });
          setLoading(false);
        });
      }
    );
  };

  const setData = async () => {
    await setDoc(doc(db, "desconto2025", uid), dados, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const err = ValidaForm2(dados);

    if (err) {
      setErro(err);
      setLoading(false);
    } else {
      setData().then(() => navigate("../form3"));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "desconto2025", uid));

    const data = qry.data();
    const status = data?.status;

    if (!status || status === "e") {
      setDados(data);
      setLoading(false);
    } else navigate("../home");
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="text-start"
            noValidate
          >
            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgRg"
                  label="RG"
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className={erro.rg && "is-invalid"}
                  onChange={(e) => uploadFile(e.target.files[0], "rg", "urlRg")}
                  erro={erro.rg}
                  url={dados?.urlRg}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgVinculo"
                  label="Comprovante de Vínculo"
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className={erro.vinculo && "is-invalid"}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "vinculo", "urlVinculo")
                  }
                  erro={erro.vinculo}
                  url={dados?.urlVinculo}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgResidencia"
                  label="Comprovante de Residência"
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className={erro.residencia && "is-invalid"}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "residencia", "urlResidencia")
                  }
                  erro={erro.residencia}
                  url={dados?.urlResidencia}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgFoto"
                  label="Foto para Carteirinha"
                  type="file"
                  accept="image/*"
                  className={erro.foto && "is-invalid"}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "foto", "urlFoto")
                  }
                  erro={erro.foto}
                  url={dados?.urlFoto}
                />
              </div>
            </div>
            <div className="alert alert-danger mt-3" role="alert">
              <p>
                <b>ATENÇÃO:</b>
              </p>
              <p>
                • A foto para carteirinha deve ser estilo 3x4 (fundo branco e de
                frente)
              </p>
              <p>
                • O comprovante de residência e o comprovante de vínculo não
                podem ser da mesma cidade
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 d-grid">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Documentos"
      />
    </>
  );
};

export default Form2;
